import React, { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children: ReactNode
  className?: string
  scheme?: Scheme
  colspan?: number
}

const Card = (props: Props) => {
  const { children, className, scheme = 'light', colspan } = props

  return (
    <Container className={className} $scheme={scheme} $colspan={colspan}>
      {children}
    </Container>
  )
}

export default Card

type Scheme = 'light' | 'dark'

const schemes = {
  light: {
    backgroundColor: 'rgba(230, 241, 253, 0.4)',
    border: '2px solid #E0EDFD',
  },
  dark: {
    backgroundColor: '#142D59',
    border: '2px solid #143763',
  },
}

const Container = styled.div<{ $scheme: Scheme; $colspan?: number }>`
  ${({ $scheme }) => schemes[$scheme]}
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 32px;
  width: 100%;
`
