import Blocks, { BlockType } from 'blocks'
import getGlobalData, { GlobalData } from 'content/getGlobalData'

import type { GetStaticPropsResult } from 'next'
import Head from 'next/head'
import { HomePageData } from 'types/strapi/objects'
import Layout from 'layout/Layout'
import React from 'react'
import getHomePageData from 'content/getHomePageData'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type HomePageProps = {
  globalData: GlobalData
  data: HomePageData
}

export default function HomePage(props: HomePageProps) {
  const { globalData, data } = props
  const { setGlobalData } = useGlobalContexts()
  setGlobalData(globalData)

  return (
    <>
      <Head>
        <title>Onetrace | Fire Stopping App</title>
      </Head>
      <Layout>
        {data.attributes.blocks.map((block, blockIndex) => {
          const key = block.__component.replace('blocks.', '')
          const BlockComponent: any = Blocks[key as BlockType]

          return (
            <React.Fragment key={blockIndex}>
              <BlockComponent {...block} />
            </React.Fragment>
          )
        })}
      </Layout>
    </>
  )
}

export async function getStaticProps(): Promise<GetStaticPropsResult<HomePageProps>> {
  const globalData = await getGlobalData()
  const data = await getHomePageData()

  return {
    props: {
      globalData,
      data,
    },
    revalidate: 1,
  }
}
