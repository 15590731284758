import Cards from './Cards'
import ContactUs from './ContactUs'
import Content from './Content'
import Hero from './Hero'
import Jobs from './Jobs'
import Legal from './Legal'
import Pricing from './Pricing'
import Questions from './Questions'
import Satisfaction from './Satisfaction'
import Solutions from './Solutions'
import Testimonial from './Testimonial'
import USP from './USP'

const Blocks = {
  hero: Hero,
  cards: Cards,
  solutions: Solutions,
  testimonial: Testimonial,
  questions: Questions,
  satisfaction: Satisfaction,
  pricing: Pricing,
  legal: Legal,
  contactus: ContactUs,
  content: Content,
  usp: USP,
  jobs: Jobs,
}

export type BlockType = keyof typeof Blocks

export default Blocks
