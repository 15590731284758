import React from 'react'
import styled from 'styled-components'

export const spaces = [0, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, undefined]

export type Space = typeof spaces[number]

export type Props = {
  className?: string
  space?: Space
  spaceBefore?: Space
  spaceAfter?: Space
}

const Divider = ({ className, space = 0, spaceBefore, spaceAfter }: Props) => (
  <Container className={className} $space={space} $spaceBefore={spaceBefore} $spaceAfter={spaceAfter}>
    <Rule />
  </Container>
)

export default Divider

const Container = styled.div<{
  $space: Space
  $spaceBefore: Space
  $spaceAfter: Space
}>`
  width: 100%;
  ${({ $space, $spaceBefore, $spaceAfter }) => ({
    paddingTop: $spaceBefore || $space,
    paddingBottom: $spaceAfter || $space,
  })}
`

const Rule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.charcoal10};
`
