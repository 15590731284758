import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import type { ColorScheme } from 'styles/types'
import Heading from 'components/Heading'
import { LegalPage } from 'content/legal-pages'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Select from 'components/Select'
import Spacer from 'components/Spacer'
import TabButton from 'components/TabButton'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  scheme?: ColorScheme
  legalPage: LegalPage
  legalPages: LegalPage[]
}

export default function LegalBlock(props: Props) {
  const { scheme = 'white', legalPage, legalPages } = props

  const headings: string[] = legalPage.content
    .split('\n')
    .filter((line: string) => line.startsWith('#### '))
    .map((line: string) => line.substring(5))

  const pageOptions = legalPages.map(($legalPage) => {
    return { label: $legalPage.title, value: $legalPage.slug }
  })

  const goToPage = React.useCallback(($slug: string) => {
    window.location.href = `/legal/${$slug}`
  }, [])

  const scrollToHeading = React.useCallback((heading: string) => {
    document.querySelectorAll(`.content h4`).forEach((_section) => {
      if (_section.innerHTML == heading) {
        _section.scrollIntoView()
        window.scrollBy(0, -100)
      }
    })
  }, [])

  return (
    <Block scheme={scheme}>
      <Spacer
        size={{
          default: 48,
          lg: 120,
        }}
      />

      <Centraliser>
        <Container>
          <Header>
            <Heading type='h2'>Legal information</Heading>
          </Header>

          <ContentContainer>
            <Directories>
              {legalPages.map(($legalPage) => {
                return (
                  <Tab key={$legalPage.title}>
                    <TabButton
                      onClick={() => goToPage($legalPage.slug)}
                      variant={$legalPage.slug == legalPage.slug ? 'blue' : 'pale'}
                    >
                      {$legalPage.title}
                    </TabButton>

                    {$legalPage.slug == legalPage.slug && (
                      <Sections>
                        {headings.map((heading) => (
                          <Section key={heading} onClick={() => scrollToHeading(heading)}>
                            {heading}
                          </Section>
                        ))}
                      </Sections>
                    )}
                  </Tab>
                )
              })}
            </Directories>

            <Contents>
              <MobileDirectory>
                <Select options={pageOptions} value={legalPage.slug} onChange={(slug: string) => goToPage(slug)} />
              </MobileDirectory>

              <Content className='content'>
                <Text type='body-large'>
                  <StyledMarkdown>{legalPage.content}</StyledMarkdown>
                </Text>
              </Content>
            </Contents>
          </ContentContainer>
        </Container>
      </Centraliser>

      <Spacer
        size={{
          default: 80,
          lg: 120,
        }}
      />
    </Block>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${theme.breakpoints.atleast.lg} {
    gap: 80px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 32px 0;
  border-bottom: 1px solid ${theme.colors.charcoal10};

  @media ${theme.breakpoints.atleast.lg} {
    padding: 0 0 80px 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.atleast.lg} {
    flex-direction: row;
    gap: 40px;
  }
`

const Directories = styled.div`
  display: none;
  gap: 8px;

  @media ${theme.breakpoints.atleast.lg} {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
`

const Tab = styled.div``

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px;
  gap: 16px;
`

const Section = styled.div`
  cursor: pointer;
  transition: color 300ms ease;
  color: ${theme.colors.charcoal60};

  ${theme.typography.h5.mobile}

  @media ${theme.breakpoints.atleast.lg} {
    ${theme.typography.h5.desktop}
  }

  &:hover {
    color: ${theme.colors.charcoal};
  }
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;

  select {
    width: 100%;
  }

  flex: 1;
`

const MobileDirectory = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${theme.breakpoints.atleast.lg} {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin-bottom: 16px !important;
  }

  h3 {
    margin-bottom: 40px;
  }

  h4 {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style: disc !important;
  }
`
