import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import React from 'react'
import Testimonial from 'components/Testimonial'
import { TestimonialBlockType } from 'types/strapi/components'

type Props = TestimonialBlockType

export default function TestimonialBlock(props: Props) {
  
  const { scheme = 'light', testimonial } = props

  if (!testimonial.data) return null

  return (
    <Block scheme={scheme} spacerTop={{ default: 32, md: 64 }} spacerBottom={{ default: 32, md: 64 }}>
      <Centraliser>
        <Testimonial {...testimonial.data.attributes} />
      </Centraliser>
    </Block>
  )
}
