import Block from 'components/Block'
import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import Flex from 'components/Flex'
import Heading from 'components/Heading'
import React from 'react'
import { SatisfactionBlockType } from 'types/strapi/components'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = SatisfactionBlockType

export default function SatisfactionBlock(props: Props) {
  const { label, heading, cta, results, extraPaddingTop, extraPaddingBottom } = props

  const { setShowLeadCaptureModal } = useGlobalContexts()

  return (
    <Block scheme='dark-blue'>
      <Spacer size={{ default: extraPaddingTop ? 120 : 60, lg: extraPaddingTop ? 160 : 80 }} />

      <Centraliser>
        <Flex column center='horizontally'>
          <IntroWrapper>
            {!!label && (
              <>
                <Text type='body-large-pullout' align='center' color='aquamarine'>
                  {label}
                </Text>
                <Spacer size={16} />
              </>
            )}

            <Heading type='h3' align='center' withMarkdown>
              {heading}
            </Heading>

            <Spacer size={24} />

            <Button onClick={() => setShowLeadCaptureModal(true)}>{cta}</Button>
          </IntroWrapper>

          <Spacer size={{ default: 64, lg: 80 }} />

          <SurveyResults>
            {results.map((result) => (
              <Result key={result.id}>
                <PercentageWrapper>
                  <PercentageAmount>{result.percentage}</PercentageAmount>
                  <PercentageSymbol>%</PercentageSymbol>
                </PercentageWrapper>
                <Spacer size={{ default: 4, lg: 8 }} />
                <Text type='body-small' color='white60'>
                  {result.description}
                </Text>
              </Result>
            ))}
          </SurveyResults>
        </Flex>
      </Centraliser>

      <Spacer size={{ default: extraPaddingBottom ? 120 : 60, lg: extraPaddingBottom ? 160 : 80 }} />
    </Block>
  )
}

const IntroWrapper = styled.div`
  max-width: 608px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SurveyResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 305px;
  margin: auto;

  @media ${theme.breakpoints.atleast.md} {
    max-width: 980px;
    justify-content: space-between;
    flex-direction: row;
  }
`

const Result = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 264px;
`

const PercentageWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
`

const PercentageAmount = styled.div`
  ${theme.typography.mega.mobile}
  @media ${theme.breakpoints.atleast.lg} {
    ${theme.typography.mega.desktop}
  }
`

const PercentageSymbol = styled.span`
  margin-bottom: 4px;
  ${theme.typography.h3.mobile}
  @media ${theme.breakpoints.atleast.lg} {
    ${theme.typography.h3.desktop}
  }
`
