import React, { ReactNode, useCallback, useEffect, useRef } from 'react'

import styled from 'styled-components'

type Props = {
  children: ReactNode
  overspill?: number
}

const InfiniteScrollLoop = (props: Props): JSX.Element => {
  const { children, overspill = 1 } = props

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const childrenRef = useRef<HTMLDivElement | null>(null)

  const scrollLeftState = useRef(0)
  const animationId = useRef<number>()

  const getChildrenWidth = () => childrenRef.current?.clientWidth || 0
  const getScrollLeft = () => scrollContainerRef.current?.scrollLeft || 0
  const scrollTo = (left: number) => scrollContainerRef.current?.scrollTo({ left })

  const handleScroll = () => {
    scrollLeftState.current = getScrollLeft()

    if (getScrollLeft() < getChildrenWidth()) {
      scrollTo(getChildrenWidth() * 2)
    }

    if (getScrollLeft() > getChildrenWidth() * 3) {
      scrollTo(getChildrenWidth() * 2)
    }
  }

  const handleMouseEnter = () => {
    if (animationId.current) {
      cancelAnimationFrame(animationId.current)
    }
  }

  const handleMouseLeave = () => {
    // animationId.current = requestAnimationFrame(autoScroll)
  }

  const autoScroll = useCallback(() => {
    const nextScrollLeft = scrollLeftState.current + 1
    scrollLeftState.current = nextScrollLeft
    scrollContainerRef.current?.scrollTo({ left: nextScrollLeft })
    animationId.current = requestAnimationFrame(autoScroll)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const initialScrollLeft = getChildrenWidth() * 2
      scrollLeftState.current = initialScrollLeft
      scrollContainerRef.current?.scrollTo({ left: initialScrollLeft })
    }, 0)
  }, [])

  useEffect(() => {
    // animationId.current = requestAnimationFrame(autoScroll)
    return () => {
      if (animationId.current) {
        cancelAnimationFrame(animationId.current)
      }
    }
  }, [autoScroll])

  return (
    <ScrollContainer
      ref={scrollContainerRef}
      onScroll={handleScroll}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Array(overspill)
        .fill('')
        .map(() => children)}
      <div ref={childrenRef}>{children}</div>
      {Array(overspill)
        .fill('')
        .map(() => children)}
    </ScrollContainer>
  )
}

export default InfiniteScrollLoop

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow-x: scroll;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
