import Block from 'components/Block'
import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import Heading from 'components/Heading'
import { HeroBlockType } from 'types/strapi/components'
import Image from 'next/image'
import React from 'react'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import clientLogos from '../public/assets/client-logos.jpg'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = HeroBlockType

export default function HeroBlock(props: Props) {
  const { scheme = 'white', heading, body, image } = props

  const { setShowLeadCaptureModal } = useGlobalContexts()

  return (
    <Block scheme={scheme}>
      <Spacer size={{ default: 64, lg: 120 }} />

      <Centraliser>
        <TextContainer>
          <Heading withMarkdown>{heading}</Heading>
          <Spacer size={{ default: 16, md: 24 }} />
          <Text type='body-large' withMarkdown>
            {body}
          </Text>
          <Spacer size={{ default: 24, md: 32 }} />
          <Button onClick={() => setShowLeadCaptureModal(true)} arrow>
            Start building for Free
          </Button>
        </TextContainer>

        <Spacer size={{ default: 40, lg: 80 }} />

        <ImageContainer>
          <Image
            src={getStrapiMedia(image.data)}
            alt={image.data.attributes.alternativeText || ''}
            fill
            className='image'
          />
        </ImageContainer>

        <Spacer size={{ default: 40, lg: 64 }} />

        <ClientLogosContainer>
          <Image src={clientLogos} alt='Client logos' fill className='image' />
        </ClientLogosContainer>
      </Centraliser>

      <Spacer size={{ default: 32, lg: 64 }} />
    </Block>
  )
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    max-width: 605px;
  }

  h1 {
    max-width: 300px;
  }

  @media ${theme.breakpoints.atleast.md} {
    h1 {
      max-width: 100%;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto;

  .image {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    transform: scale(1.1);
  }
`

const ClientLogosContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: auto;

  @media ${theme.breakpoints.atleast.md} {
    width: 500px;
  }

  .image {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
  }
`
