import Block from 'components/Block'
import Card from 'components/Card'
import Centraliser from 'components/Centraliser'
import Heading from 'components/Heading'
import Image from 'next/image'
import InfiniteScrollLoop from 'components/InfiniteScrollLoop'
import { SolutionsBlockType } from 'types/strapi/components'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

export default function SolutionsBlock(props: SolutionsBlockType) {
  const { scheme = 'dark-neon', label, heading, extraPaddingTop, extraPaddingBottom } = props

  const { solutionPreviews } = useGlobalContexts()

  return (
    <Block scheme={scheme}>
      <Spacer size={{ default: extraPaddingTop ? 120 : 60, lg: extraPaddingTop ? 160 : 80 }} />

      <Centraliser>
        <Container>
          {!!label && (
            <>
              <Text type='body-large-pullout' align='center' color='aquamarine'>
                {label}
              </Text>
              <Spacer size={16} />
            </>
          )}

          <Heading type='h2' align='center' withMarkdown>
            {heading}
          </Heading>

          <Spacer size={{ default: 64, lg: 80 }} />
        </Container>
      </Centraliser>

      <InfiniteScrollLoop overspill={3}>
        <Cards>
          {solutionPreviews.map((solution) => (
            <CardSlot key={solution.id}>
              <Card scheme={scheme.startsWith('dark') ? 'dark' : 'light'}>
                <ImageContainer>
                  {solution.image?.data && (
                    <Image
                      src={getStrapiMedia(solution?.image?.data)}
                      alt={solution.image.data.attributes.alternativeText || ''}
                      fill
                      className='image'
                    />
                  )}
                </ImageContainer>

                <CopyContainer>
                  <CopyMaxWidth>
                    <Heading type='h4' align='center'>
                      {solution.heading}
                    </Heading>
                    <Spacer size={8} />
                    <Text type='body-small' align='center' color={scheme.startsWith('dark') ? 'white60' : 'charcoal'}>
                      {solution.body}
                    </Text>
                  </CopyMaxWidth>
                </CopyContainer>
              </Card>
            </CardSlot>
          ))}
        </Cards>
      </InfiniteScrollLoop>

      <Spacer size={{ default: extraPaddingBottom ? 120 : 60, lg: extraPaddingBottom ? 160 : 80 }} />
    </Block>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Cards = styled.div`
  display: flex;
  width: fit-content;
`

const CardSlot = styled.div`
  display: flex;
  width: 295px;
  margin-right: 16px;
  @media ${theme.breakpoints.atleast.lg} {
    width: 410px;
    margin-right: 24px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 280px;

  @media ${theme.breakpoints.atleast.lg} {
    height: 358px;
  }

  .image {
    position: relative !important;
    width: auto !important;
    height: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
`

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  padding-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;

  @media ${theme.breakpoints.atleast.md} {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 56px;
  }
`

const CopyMaxWidth = styled.div`
  max-width: 400px;
`
