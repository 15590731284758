import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import { JobsBlockType } from 'types/strapi/components'
import React from 'react'
import Script from 'next/script'
import Spacer from 'components/Spacer'

type Props = JobsBlockType

export default function JobsBlock(props: Props) {
  const { scheme = 'white' } = props

  return (
    <Block scheme={scheme}>
      <Spacer
        size={{
          default: 48,
          lg: 120,
        }}
      />

      <Centraliser>
        <div id='BambooHR' data-domain='onetrace.bamboohr.com' data-version='1.0.0' data-departmentId='' />
        <Script
          strategy='afterInteractive'
          src='https://onetrace.bamboohr.com/js/embed.js'
          type='text/javascript'
          async
          defer
        />
      </Centraliser>

      <Spacer
        size={{
          default: 48,
          lg: 120,
        }}
      />
    </Block>
  )
}
