import Heading from './Heading'
import Image from 'next/image'
import React from 'react'
import Spacer from './Spacer'
import { StrapiMedia } from 'types/strapi'
import Text from './Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import theme from 'styles/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: ${theme.colors.paleBlueHover};
  border-radius: 24px;
  justify-content: space-between;

  @media ${theme.breakpoints.atleast.md} {
    flex-direction: row;
    padding: 64px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.atleast.md} {
    width: calc(100% - 200px);
  }
`

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 150px);

  h6 {
    color: ${theme.colors.blue};
    line-height: 16px;

    &:last-child {
      color: ${theme.colors.charcoal};
    }
  }
`

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: -40px;

  width: 120px;
  height: 120px;

  @media ${theme.breakpoints.atleast.md} {
    width: 160px;
    height: 160px;
    margin-top: 0px;
  }

  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(33, 100, 189, 0.3);
  border-radius: 20px;
  overflow: hidden;

  .image {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
  }
`

type Props = {
  quote: string
  person: string
  position: string
  logo: { data: StrapiMedia }
}

const Testimonial: React.FC<Props> = (props) => {
  const { quote, person, position, logo } = props

  return (
    <Container>
      <TextContainer>
        <Heading type='h3'>{quote}</Heading>
        <Spacer size={32} />
        <AuthorContainer>
          <Text type='h6' color='blue'>{person}</Text>
          <Text type='h6'>{position}</Text>
        </AuthorContainer>
      </TextContainer>

      <LogoContainer>
        <Image
          src={getStrapiMedia(logo.data)}
          alt={logo.data.attributes.alternativeText || ''}
          fill
          className='image'
        />
      </LogoContainer>
    </Container>
  )
}

export default Testimonial
