import { useEffect, useState } from 'react'

import Block from 'components/Block'
import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import type { ColorScheme } from 'styles/types'
import Heading from 'components/Heading'
import Illustration from 'components/Illustration'
import LeadCaptureForm from 'forms/LeadCaptureForm'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import { delay } from 'lodash'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  scheme?: ColorScheme
}

export default function ContactUsBlock(props: Props) {
  const { scheme = 'white' } = props

  const { setShowLeadCaptureModal, setShowThanksModal } = useGlobalContexts()

  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    delay(() => setShowForm(true), 500)
  }, [])

  return (
    <Block scheme={scheme}>
      <Spacer size={{ default: 48, lg: 120 }} />

      <Centraliser>
        <Container>
          <Header>
            <Heading type='h2'>Get in Touch</Heading>
            <Spacer size={{ default: 16, lg: 24 }} />
            <Text type='body-large'>{`Need to get in touch with the team? We're all ears.`}</Text>
          </Header>

          <BodyWrapper>
            <Cards>
              <Card>
                <Heading type='h3'>Book Demo</Heading>
                <Spacer size={{ default: 24 }} />
                <CardCopy>
                  <Text type='body-small' wrapper='p'>
                    Interested in seeing Onetrace in action?{' '}
                    <b>Let one of our representatives take you round the system.</b>
                  </Text>
                </CardCopy>
                <Spacer size={{ default: 40 }} />
                <Bottom>
                  <Button arrow onClick={() => setShowLeadCaptureModal(true)}>
                    Request Demo
                  </Button>
                  <Illustration name='72x72:cogs' />
                </Bottom>
              </Card>

              <Card>
                <Heading type='h3'>Live Chat</Heading>
                <Spacer size={{ default: 24 }} />
                <CardCopy>
                  <Text type='body-small' wrapper='p'>
                    Our live chat is open <b>Mon – Fri, 9am – 5pm.</b> Our team typically responds within a few minutes.
                  </Text>
                </CardCopy>
                <Spacer size={{ default: 40 }} />
                <Bottom>
                  <Button arrow onClick={() => window.Intercom('show')}>
                    Send Message
                  </Button>
                  <Illustration name='72x72:chat' />
                </Bottom>
              </Card>
            </Cards>

            {showForm && (
              <Form>
                <Heading type='h3'>Talk to Sales</Heading>

                <Spacer size={{ default: 16, md: 24 }} />

                <Text type='body-large'>
                  Want to speak with someone? Just leave us your details and{' '}
                  <strong>one of our team will give you a call.</strong>
                </Text>

                <Spacer size={{ default: 32, md: 40 }} />

                <LeadCaptureForm
                  onSuccess={() => setShowThanksModal(true)}
                  formType='callback-request'
                  submitText='Request Callback'
                />
              </Form>
            )}
          </BodyWrapper>
        </Container>
      </Centraliser>

      <Spacer size={{ default: 80, lg: 120 }} />
    </Block>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${theme.breakpoints.atleast.lg} {
    gap: 80px;
  }
`

const Header = styled.div``

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media ${theme.breakpoints.atleast.lg} {
    flex-direction: row;
  }
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${theme.breakpoints.atleast.sm} {
    flex-direction: row;
  }

  @media ${theme.breakpoints.atleast.lg} {
    flex-direction: column;
    width: 440px;
  }
`

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  background: ${theme.colors.paleBlue};
  border-radius: 24px;

  @media ${theme.breakpoints.atleast.lg} {
    flex: 0;
  }
`

const CardCopy = styled.div`
  flex: 1;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > img {
    width: 56px;
    height: 56px;
  }

  @media ${theme.breakpoints.atleast.lg} {
    & > img {
      width: 72px;
      height: 72px;
    }
  }
`

const Form = styled.div`
  max-width: 440px;

  @media ${theme.breakpoints.atleast.lg} {
    margin: auto;
  }
`
