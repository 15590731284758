import Block from 'components/Block'
import Card from 'components/Card'
import { CardsBlockType } from 'types/strapi/components'
import Centraliser from 'components/Centraliser'
import Divider from 'components/Divider'
import Flex from 'components/Flex'
import Heading from 'components/Heading'
import Image from 'next/image'
import React from 'react'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = CardsBlockType

export default function CardsBlock(props: Props) {
  const {
    scheme = 'dark-blue',
    label,
    heading,
    body,
    columns,
    cards,
    testimonial,
    extraPaddingTop,
    extraPaddingBottom,
  } = props

  return (
    <Block scheme={scheme}>
      <Spacer size={{ default: extraPaddingTop ? 120 : 60, lg: extraPaddingTop ? 160 : 80 }} />

      <Centraliser>
        <Flex column center='horizontally'>
          <IntroWrapper>
            {!!label && (
              <>
                <Text
                  type='body-large-pullout'
                  align='center'
                  color={scheme.startsWith('dark') ? 'aquamarine' : 'blue'}
                >
                  {label}
                </Text>
                <Spacer size={16} />
              </>
            )}

            <Heading type='h2' align='center' withMarkdown>
              {heading}
            </Heading>

            {!!body && (
              <>
                <Spacer size={24} />
                <Text type='body-large' align='center' withMarkdown>
                  {body}
                </Text>
              </>
            )}
          </IntroWrapper>

          <Spacer size={{ default: 64, lg: 80 }} />

          <Grid $columns={columns}>
            {cards.map((card) => (
              <GridSlot key={card.id} $colspan={card.colspan}>
                <Card scheme={scheme.startsWith('dark') ? 'dark' : 'light'}>
                  <ImageContainer>
                    {card.image?.data && (
                      <Image
                        src={getStrapiMedia(card?.image?.data)}
                        alt={card.image.data.attributes.alternativeText || ''}
                        fill
                        className='image'
                      />
                    )}
                  </ImageContainer>

                  <CopyContainer>
                    <CopyMaxWidth>
                      <Heading type='h4' align='center'>
                        {card.heading}
                      </Heading>
                      <Spacer size={8} />
                      <Text type='body-small' align='center' color={scheme.startsWith('dark') ? 'white60' : 'charcoal'}>
                        {card.body}
                      </Text>
                    </CopyMaxWidth>
                  </CopyContainer>
                </Card>
              </GridSlot>
            ))}
          </Grid>

          {testimonial.data && (
            <TestimonialWrapper>
              <Spacer size={{ default: 64, lg: 80 }} />

              <TestimonialIcon>
                <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M25 8.125C25 6.74429 23.8807 5.625 22.5 5.625H7.5C6.11929 5.625 5 6.74429 5 8.125V19.375V22.8661C5 23.4229 5.67321 23.7018 6.06694 23.3081L9.00888 20.3661C9.2433 20.1317 9.56125 20 9.89277 20H15.625H22.5C23.8807 20 25 18.8807 25 17.5V12.8125V8.125Z'
                    stroke='white'
                    strokeWidth='1.5625'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                  />
                  <circle
                    cx='1.5625'
                    cy='1.5625'
                    r='1.5625'
                    transform='matrix(4.37114e-08 1 1 -4.37114e-08 13.4375 11.25)'
                    fill='white'
                  />
                  <circle
                    cx='1.5625'
                    cy='1.5625'
                    r='1.5625'
                    transform='matrix(4.37114e-08 1 1 -4.37114e-08 8.75 11.25)'
                    fill='white'
                  />
                  <circle
                    cx='1.5625'
                    cy='1.5625'
                    r='1.5625'
                    transform='matrix(4.37114e-08 1 1 -4.37114e-08 18.125 11.25)'
                    fill='white'
                  />
                </svg>
              </TestimonialIcon>

              <Spacer size={{ default: 24 }} />

              <Text type='body-large-pullout' align='center'>
                {testimonial.data.attributes.quote}
              </Text>

              <Spacer size={{ default: 24 }} />

              <Text type='h5' color='charcoal60' align='center'>
                {testimonial.data.attributes.person}
              </Text>

              <Spacer size={{ default: 64, lg: 80 }} />

              <Divider />
            </TestimonialWrapper>
          )}
        </Flex>
      </Centraliser>

      <Spacer size={{ default: extraPaddingBottom ? 120 : 60, lg: extraPaddingBottom ? 160 : 80 }} />
    </Block>
  )
}

const IntroWrapper = styled.div`
  max-width: 840px;
`

const Grid = styled.div<{ $columns: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;

  @media ${theme.breakpoints.atleast.md} {
    gap: 24px;
  }
`

const GridSlot = styled.div<{ $colspan?: number }>`
  display: flex;
  grid-column-start: span 12;

  @media ${theme.breakpoints.atleast.md} {
    ${({ $colspan }) => ($colspan === 4 || $colspan === 8 ? { gridColumnStart: `span 6` } : undefined)}
  }

  @media ${theme.breakpoints.atleast.lg} {
    ${({ $colspan }) => ($colspan ? { gridColumnStart: `span ${$colspan}` } : undefined)}
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 280px;

  @media ${theme.breakpoints.atleast.lg} {
    height: 380px;
  }

  .image {
    position: relative !important;
    width: auto !important;
    height: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
`

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  padding-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;

  @media ${theme.breakpoints.atleast.md} {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 56px;
  }
`

const CopyMaxWidth = styled.div`
  max-width: 400px;
`

const TestimonialWrapper = styled.div`
  max-width: 608px;
  margin: auto;
`

const TestimonialIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${theme.colors.blue};
  border-radius: 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
