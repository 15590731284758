import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import { ContentBlockType } from 'types/strapi/components'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Spacer from 'components/Spacer'
import styled from 'styled-components'
import theme from 'styles/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 869px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    margin: 64px 0;
  }

  @media ${theme.breakpoints.atleast.lg} {
    img {
      margin: 80px 0;
    }
  }
`

type Props = ContentBlockType

export default function ContentBlock(props: Props) {

  const { content } = props

  return (
    <Block scheme="white">
      <Spacer
        size={{
          default: 48,
          lg: 120,
        }}
      />

      <Centraliser>
        <Container>
          <ReactMarkdown>{content}</ReactMarkdown>
        </Container>
      </Centraliser>

      <Spacer
        size={{
          default: 48,
          lg: 120,
        }}
      />
    </Block>
  )
}
