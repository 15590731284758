import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

type TabButtonVariant = 'blue' | 'pale'

type ButtonElementProps = {
  readonly $variant: TabButtonVariant
}

const ButtonElement = styled.div<ButtonElementProps>`
  border: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  height: ${theme.surfaces.tabButton.size.height.mobile};
  padding: ${theme.surfaces.tabButton.size.padding.mobile};
  border-radius: ${theme.surfaces.tabButton.size.borderRadius.mobile};
  font-size: ${theme.surfaces.tabButton.size.fontSize.mobile};
  line-height: ${theme.surfaces.tabButton.size.lineHeight.mobile};
  font-weight: ${theme.surfaces.tabButton.size.fontWeight.mobile};

  background-color: ${({ $variant }) => theme.surfaces.tabButton.variant[$variant].background};
  color: ${({ $variant }) => theme.surfaces.tabButton.variant[$variant].color} !important;

  &:hover {
    background-color: ${({ $variant }) => theme.surfaces.tabButton.variant[$variant].backgroundHover};
  }
`

type Props = {
  href?: string
  onClick?: () => void
  variant?: TabButtonVariant
  children: React.ReactNode
}

const TabButton: React.FC<Props> = (props) => {
  const { href, onClick, variant = 'pale', children } = props

  return (
    <ButtonElement as={href ? 'a' : undefined} href={href || undefined} onClick={onClick} $variant={variant}>
      {children}
    </ButtonElement>
  )
}

export default TabButton
